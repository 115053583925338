<template>
  <div class="maintain">
    <h1 class="page_title">{{ $route.meta.title }}</h1>
    <p class="maintain-info">设置后提前4小时开始在储能管家出现横幅提示</p>

    <a-form :form="form" @submit="handleSubmit">
      <a-form-item label="">
        <a-button
          type="primary"
          v-show="disabled"
          @click="disabled = !disabled"
        >
          编辑
        </a-button>
        <a-button type="primary" html-type="submit" v-show="!disabled">
          保存
        </a-button>
      </a-form-item>
      <a-form-item label="操作类型：">
        <a-radio-group
          v-decorator="[
            'operateType',
            { rules: [{ required: true, message: '请选择操作类型!' }] },
          ]"
          :disabled="disabled"
        >
          <a-radio :style="radioStyle" :value="1"> 版本升级</a-radio>
          <a-radio :style="radioStyle" :value="2"> 服务维护 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="维护日期:">
        <a-date-picker
          style="width: 300px"
          v-decorator="[
            'operateDate',
            { rules: [{ required: true, message: '请选择维护日期!' }] },
          ]"
          :disabledDate="disabledDate"
          :disabled="disabled"
        />
      </a-form-item>
      <a-form-item label="开始时间:">
        <a-time-picker
          style="width: 300px"
          v-decorator="[
            'time',
            { rules: [{ required: true, message: '请选择开始时间!' }] },
          ]"
          format="HH:mm"
          :open="open"
          @change="onChange"
          @openChange="handleOpenChange"
          :disabled="disabled"
        />
      </a-form-item>
      <a-form-item label="持续时长：">
        <a-input
          style="width: 300px"
          v-decorator="[
            'lastHour',
            { rules: [{ required: true, message: '请输入持续时长!' }] },
          ]"
          :disabled="disabled"
        >
          <span slot="addonAfter">小时</span>
        </a-input>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { bannerSet, bannerGet } from "@/api/index";
export default {
  name: "Maintain",
  data() {
    return {
      form: this.$form.createForm(this),
      disabled: true,
      open: false,
      radioStyle: {
        marginRight: "30px",
      },
      flag: true,
    };
  },
  created() {},
  mounted() {
    this.getbanner();
  },
  methods: {
    getbanner() {
      bannerGet({})
        .then((res) => {
          console.log(res);
          this.form.setFieldsValue({
            operateType: res.data.operateType || "",
            operateDate: this.$moment(res.data.operateDate).format(
              "YYYY-MM-DD" || ""
            ),
            time: this.$moment(res.data.time, "HH:mm") || "",
            lastHour: res.data.lastHour || "",
          });
        })
        .finally(() => {});
    },
    //限制当天之前的日期不可选
    disabledDate(current) {
      return current && current < this.$moment().subtract(1, "days"); //当天之前的不可选，不包括当天
      //return current && current < moment().endOf(‘day');当天之前的不可选，包括当天
    },
    handleOpenChange(open) {
      console.log("open", open);
      this.open = open;
    },
    onChange(time) {
      if (
        this.$moment(time).format("HH:mm") <
        this.$moment().add(1, "minute").format("HH:mm")
      ) {
        this.$message.error("必须选择当前时间之后的时刻");
        this.flag = false;
      } else {
        this.open = false;
        this.flag = true;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //   console.log("Received values of form: ", values);
          var reg = /^[0-9]+(\.[0-9]{1})?$/;
          if (
            this.$moment(values.time).format("HH:mm") <
            this.$moment().add(1, "minute").format("HH:mm")
          ) {
            this.$message.error("必须选择当前时间之后的时刻");
            this.flag = false;
          } else {
            if (!reg.test(values.lastHour)) {
              this.$message.error("只能输入数字，且最多保留一位小数");
              this.flag = false;
            } else {
              this.flag = true;
            }
          }
          if (this.flag) {
            console.log(8888);
            bannerSet({
              operateType: values.operateType,
              operateDate: this.$moment(values.operateDate).format(
                "YYYY-MM-DD"
              ),
              time: this.$moment(values.time).format("HH:mm"),
              lastHour: values.lastHour,
            })
              .then(() => {
                this.disabled = !this.disabled;
                this.$message.success("操作成功");
              })
              .catch(() => this.$message.error("操作失败,接口响应异常"))
              .finally(() => {});
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.maintain {
  &-info {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  /deep/ .ant-form-item {
    display: flex;
  }
}
</style>
<style  lang="less">
.ant-calendar {
  width: 300px;
}
.ant-time-picker-panel-inner {
  width: 300px;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 300px;
}
.ant-time-picker-panel-select ul {
  width: 150px;
}
.ant-time-picker-panel-select {
  width: 150px;
}
</style>

